/**
 * @file CheckoutForm.js
 * @module CheckoutForm
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires @stripe/react-stripe-js - React library
 * @requires @stripe/stripe-js - Module for additional functionalities
 * @requires firebase/firestore - Module for additional functionalities
 * @requires @stripe/react-stripe-js - React library
 * @requires react-icons/ai - React library
 * 
 * @example
 * // Example usage:
 * <CheckoutForm prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { auth, firestore } from '../../firebaseConfig'; // Firestore config
import { doc, setDoc } from 'firebase/firestore'; // Firestore methods
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { AiFillSafetyCertificate } from "react-icons/ai";
import BadgeIcon from '../../assets/images/badges/badge.png'; 
import Logo from '../../assets/images/resources/logo.png';
import './CheckoutForm.css'; 

const stripePromise = loadStripe('pk_live_51Pwu5DHO9ydTKHSum141uFh5HIGF2eF9fqBqD2vCArWkqwPhWMsRBrHvCG3lOqgM0zkoHc427CVkTaf11oieSU4d00tY472Nhh');

const CheckoutForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    propertyAddress: '',
    status: 'NEW',
    twoFactorAuth: Math.floor(10000 + Math.random() * 90000),
    acknowledged: false,
    plan: null,
  });
  const [total, setTotal] = useState(0);
  const [discount] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [paymentRequest, setPaymentRequest] = useState(null); // Define paymentRequest state
  const addressInputRef = useRef(null);
  const navigate = useNavigate();
  
  const stripe = useStripe();
  const elements = useElements();

  const plans = [
    { id: 1, label: '6-month Listing', price: 149.99 },
    { id: 2, label: '12-month Listing', bonus: 'with MAX EXPOSURE', price: 239.99 },
  ];

  const taxRate = 0.13; 

  useEffect(() => {
    // Google Places Autocomplete for address input
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
        componentRestrictions: { country: 'ca' },
        fields: ['formatted_address'],
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.formatted_address) {
          setFormData((prev) => ({
            ...prev,
            propertyAddress: place.formatted_address,
          }));
        }
      });
    }

    // Stripe PaymentRequest (Apple Pay/Google Pay)
    if (
      stripe &&
      formData.name &&
      formData.email &&
      formData.phone &&
      formData.propertyAddress &&
      formData.plan
    ) {
      const pr = stripe.paymentRequest({
        country: 'CA', // Adjust as needed
        currency: 'cad', // Adjust as needed
        total: {
          label: formData.plan.label, // Label from the selected plan
          amount: Math.round(total * 100), // Total amount in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr); // Enable the button if the device supports it
        }
      });

      pr.on('paymentmethod', async (ev) => {
        console.log('Payment Method Details:', ev.paymentMethod);
      
        try {
          // Step 1: Check if the user already exists
          const signInMethods = await auth.fetchSignInMethodsForEmail(formData.email);
      
          let userId;
      
          if (signInMethods.length > 0) {
            // User exists, sign them in using a known password or provide a fallback
            try {
              const existingUser = await auth.signInWithEmailAndPassword(
                formData.email,
                `${formData.email.split('@')[0]}1801` // Assuming the default password pattern
              );
              userId = existingUser.user.uid;
            } catch (signInError) {
              setMessage('Failed to sign in. Please check your email and password.');
              ev.complete('fail');
              throw signInError;
            }
          } else {
            // If user doesn't exist, create a new user
            const newUser = await auth.createUserWithEmailAndPassword(
              formData.email,
              `${formData.email.split('@')[0]}1801`
            );
            userId = newUser.user.uid;
      
            // Optionally, create a user document in Firestore
            await setDoc(doc(firestore, 'users', userId), {
              email: formData.email,
              role: 'limited',
              createdAt: new Date().toISOString(),
            });
          }
      
          // Step 2: Process the payment intent
          const response = await fetch('https://us-central1-rocketlistings-4820b.cloudfunctions.net/createPaymentIntent', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              paymentMethodId: ev.paymentMethod.id,
              email: formData.email,
              amount: Math.round(total * 100), // Ensure amount is sent in cents
              name: formData.name,
              planId: formData.plan.id,
              planLabel: formData.plan.label,
              phone: formData.phone,
              setupFutureUsage: true,
              status: formData.status,
              twoFactorAuth: formData.twoFactorAuth,
              address: {
                line1: formData.propertyAddress,
              },
            })
          });
      
          const { clientSecret, error, paymentIntentId } = await response.json();
          if (error) {
            console.error('Error creating PaymentIntent:', error);
            setMessage(`Payment failed: ${error}`);
            ev.complete('fail');
            return;
          }
      
          // Step 3: Confirm the payment using the clientSecret
          const confirmResult = await stripe.confirmCardPayment(clientSecret);
      
          if (confirmResult.error) {
            console.error('Error confirming payment:', confirmResult.error);
            setMessage(`Payment confirmation failed: ${confirmResult.error.message}`);
            ev.complete('fail');
            return;
          }
      
          console.log('PaymentIntent Client Secret:', clientSecret);
          ev.complete('success');
          setMessage('Payment successful!');
      
          // Step 4: Save the listing to Firestore with the correct userId
          await firestore.collection('listings').doc(paymentIntentId).set({
            userId, // Use the real userId
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            propertyAddress: formData.propertyAddress,
            status: formData.status,
            twoFactorAuth: formData.twoFactorAuth,
            plan: {
              id: formData.plan.id,
              label: formData.plan.label,
              price: total,
            },
            stripe: {
              paymentIntentId: paymentIntentId,
              paymentMethodId: ev.paymentMethod.id,
            },
            createdAt: new Date().toISOString(),
          });
      
          console.log('Listing saved successfully!');
          setMessage('Listing created successfully!');
      
          // Step 5: Navigate to /oto after the listing is created with state
          navigate('/oto', {
            state: {
                paymentIntentId: paymentIntentId,
                userId: userId,
                planId: formData.plan.id,
                planLabel: formData.plan.label,
            },
          });
          
        } catch (error) {
          console.error('Payment processing or listing creation error:', error);
          setMessage('Payment successful, but listing was not created. Please contact support.');
        }
      });      
    }
}, [stripe, formData, total, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };  

  const handlePlanChange = (planId) => {
    const selectedPlan = plans.find((plan) => plan.id === parseInt(planId));
    const calculatedTotal = selectedPlan ? selectedPlan.price + selectedPlan.price * taxRate - discount : 0;
    setFormData((prev) => ({
      ...prev,
      plan: selectedPlan || {},
    }));
    setTotal(calculatedTotal);
  };  

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(true); // Start loading state

    if (!stripe || !elements) return;

    const cardNumberElement = elements.getElement(CardNumberElement);

    try {
      // Step 1: Check if the user already exists
      const signInMethods = await auth.fetchSignInMethodsForEmail(formData.email);
  
      let userId;
  
      if (signInMethods.length > 0) {
          // User exists, sign them in using a known password or provide a fallback
          try {
              const existingUser = await auth.signInWithEmailAndPassword(
                  formData.email,
                  `${formData.email.split('@')[0]}1801` // Assuming the default password pattern
              );
              userId = existingUser.user.uid;
          } catch (signInError) {
              if (signInError.code === 'auth/email-already-in-use') {
                  // Custom error message for existing email with a link to /my-account
                  setMessage('<b>This email address exists already.</b> <u><a href="/my-account">Please click here to go to My Account to create a new listing</a></u> or use another email address.');
              } else {
                  // For other potential sign-in errors
                  setMessage('Failed to sign in. Please check your email and password.');
              }
              throw signInError;
          }
      } else {
          // If user doesn't exist, create a new user
          const newUser = await auth.createUserWithEmailAndPassword(
              formData.email,
              `${formData.email.split('@')[0]}1801`
          );
          userId = newUser.user.uid;
  
          // Optionally, create a user document in Firestore
          await setDoc(doc(firestore, 'users', userId), {
              email: formData.email,
              role: 'limited',
              createdAt: new Date().toISOString(),
          });
      }
  
      // Step 2: Proceed with payment using Stripe
      const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
          billing_details: {
              name: formData.name,
              email: formData.email,
          },
      });
  
      if (error) {
          setMessage(`There was an issue with your payment. ${error.message}`);
          throw new Error(error.message);
      }
  
      // Call Firebase function to create the payment intent
      const response = await fetch('https://us-central1-rocketlistings-4820b.cloudfunctions.net/createPaymentIntent', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              paymentMethodId: paymentMethod.id,
              email: formData.email,
              amount: Math.round(total * 100), // amount in cents
              setupFutureUsage: true,
              planId: formData.plan.id,
              planLabel: formData.plan.label,
              name: formData.name,
              phone: formData.phone,
              address: {
                line1: formData.propertyAddress,
              },
              status: formData.status,
              twoFactorAuth: formData.twoFactorAuth,
          }),
      });
  
      const result = await response.json();
  
      if (result.error) {
          throw new Error(result.error);
      }
  
      if (result.paymentIntentId) {
          localStorage.setItem('paymentIntentId', result.paymentIntentId);
  
          // Step 3: Save the listing in Firestore with the userId
          await firestore.collection('listings').doc(result.paymentIntentId).set({
              userId, // Attach the userId to the listing
              name: formData.name,
              email: formData.email,
              phone: formData.phone,
              propertyAddress: formData.propertyAddress,
              status: formData.status,
              twoFactorAuth: formData.twoFactorAuth,
              plan: {
                  id: formData.plan.id,
                  label: formData.plan.label,
                  price: total,
              },
              stripe: {
                  paymentIntentId: result.paymentIntentId,
                  paymentMethodId: paymentMethod.id,
              },
              createdAt: new Date().toISOString(),
          });
  
          setMessage('Payment successful!');
          navigate('/oto', {
            state: {
                paymentIntentId: result.paymentIntentId,
                userId: userId,
                planId: formData.plan.id,
                planLabel: formData.plan.label,
            },
        });
        
      }
  } catch (error) {
      // Generic error handling
      if (error.code === 'auth/email-already-in-use') {
          setMessage('<b>This email address exists already.</b> Please <a href="/my-account">login to My Account</a> to create a new listing or use another email address.');
      } else {
          setMessage(`Payment error: ${error.message}`);
      }
  } finally {
      setLoading(false); // End loading state
  }  
};  

  return (
    <div className="checkout-wrapper">
      <div className="checkout-container">
        <div className="form-social-container">
          {/* Form Section */}
          <div className="form-section">
            <h1
              style={{
                color: '#f59613',
              }}
            >
              <AiFillSafetyCertificate
                style={{
                  paddingRight: '10px',
                  fontSize: '50px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }} /> 
              Secure checkout</h1>
            <br />
            <section>
              <h4>Step 1 - Property information</h4>
              <div className="personal-details">
                <input
                  type="text"
                  name="propertyAddress"
                  placeholder="Property address..."
                  value={formData.propertyAddress || ""} // Avoid undefined by using empty string as fallback
                  onChange={handleInputChange}
                  ref={addressInputRef}
                  required
                  className="input-field"
                  style={{ 
                    marginBottom: '20px',
                   }}
                />
              </div>
              <div className="personal-details">
                <input
                  type="text"
                  name="name"
                  placeholder="Name..."
                  value={formData.name || ""}
                  onChange={handleInputChange}
                  required
                  className="input-field"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email address..."
                  value={formData.email || ""}
                  onChange={handleInputChange}
                  required
                  className="input-field"
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone number..."
                  value={formData.phone || ""}
                  onChange={handleInputChange}
                  required
                  className="input-field"
                />
              </div>
            </section>

            <hr />
            <section className="plan-section">
              <h4>Step 2 - Choose listing package</h4>
              <div className="plan-selection">
              {plans.map((plan) => (
                <label key={plan.id} className="plan-label">
                  <input
                    type="radio"
                    name="plan"
                    value={plan.id}
                    onChange={(e) => handlePlanChange(e.target.value)}
                    checked={formData.plan && formData.plan.id === plan.id}
                  />
                  <div className="plan-info">
                    <span className="plan-label-text">{plan.label}</span>
                    {plan.bonus && <span className="plan-bonus">{plan.bonus}</span>}
                  </div>
                  <span className="plan-price">${plan.price}</span>
                </label>
              ))}
            </div>
            </section>

            <hr />
            <section className="payment-section">
              <h4>Step 3 - Complete payment and list!</h4>
              {/* Wallet Payment Button (Apple Pay / Google Pay) */}
              {paymentRequest && (
                <>
                  <div
                    style={{
                      maxWidth: '400px',
                    }}
                  >
                    <PaymentRequestButtonElement
                      options={{ paymentRequest }}
                      style={{ 
                        paymentRequestButton: { type: 'default', theme: 'light' },
                        padding: '100px',
                      }}
                    />
                  </div>
                  <p
                    style={{
                      color: '#f59613',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      marginTop: '18px',
                      marginBottom: '15px',
                    }}
                  >Or, pay with your credit card below:</p>
                </>
              )}
              {/* <div>
                <img src={CreditCardIcons} className="credit-card-icons" alt="Credit Cards" />
              </div> */}
              <form onSubmit={handlePaymentSubmit}>
                <div className="credit-card-row">
                  <div className="credit-card-field">
                    <label>Card number</label>
                    <CardNumberElement
                      className="StripeElement"
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="credit-card-field">
                    <label>Expiry date</label>
                    <CardExpiryElement
                      className="StripeElement"
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="credit-card-field">
                    <label>Security code (CVC)</label>
                    <CardCvcElement
                      className="StripeElement"
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                {message && (
                <div className="stripe-message alert-warning" style={{ border: '1px solid grey', padding: '10px', marginTop: '30px' }}>
                    ALERT: Listing did not go through.<br /><br />
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                </div>
              )}
                <button type="submit" className="submit-button" disabled={loading}>
                  {loading ? 'Processing...' : (
                    <>
                      Complete Payment
                      <AiFillSafetyCertificate 
                        style=
                        {{ 
                          paddingLeft: '10px',
                          fontSize: '40px', 
                        }} 
                        />
                    </>
                  )}
                </button>
                <h4
              style={{
                fontStyle: 'italic',
                marginTop: '40px',
                opacity: '0.35',
                lineHeight: '1.75',
                fontWeight: 'normal',
                fontSize: '24px',
              }}
            >
              "Join thousands of people just like you that have posted their mere postings on REALTOR.ca!"
            </h4>
              </form>
            </section>
          </div>

          {/* Social Proof Section */}
          <div className="social-proof-section">
            <img 
              src={Logo} 
              alt="RocketListings" 
              style={{
                maxWidth: '250px',
              }}
            />
            <h4
              style={{
                textAlign: 'center',
                fontStyle: 'italic',
                marginTop: '10px',
                marginBottom: '30px',
              }}
            >"Start your listing on REALTOR.ca in under a minute!"</h4>
            <br />
            <img 
              src={BadgeIcon} 
              alt="Trust Badge" 
              style={{ 
                opacity: '.5',
                maxWidth: '250px',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Checkout = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default Checkout;